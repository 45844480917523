.file-input-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    cursor: pointer;
  }
  
  .file-input-label::before {
    content: 'Choose File';
    display: inline-block;
    padding: 8px 12px;
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .file-input-label:hover::before {
    background-color: #e0e0e0;
  }
  
  .file-input {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }
  
  .file-name {
    margin-top: 8px;
    font-size: 14px;
    color: #666;
  }

  .spinner {
    width: 20px;
    height: 20px;
    border: 3px solid transparent;
    border-top: 3px solid #FFF;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    left: 15px; 
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  