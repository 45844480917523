/* MyCaseBox.css - Keeping original properties */
.casebox-container {
  height: 720px;
  border-radius: 12px 0px 0px 12px;
  background: linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%);
  width: 100%;
}

.casebox-header-title {
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 350px;
}
.casebox-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  box-shadow: 24px;
  padding: 24px;
  background-color: #fff;
  outline: none;
  border-radius: 12px;
  background: var(--Gradient-Light, linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%));
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
}
.casebox-search {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  border-radius: 12px;
  background: var(--Neutral-White, #FFF);
  height: 40px;
}

.casebox-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
}

.casebox-filter-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end  ;
  width: 100%;
  flex-wrap: nowrap;
  gap: 2px;
}

.casebox-date-input {
  padding: 0px 12px;
  border-radius: 12px;
  border: 1px solid var(--Primary-10, #CEE1FD);
  background: var(--Neutral-White, #FFF);
  height: 40px;
  width: 140px;
}

.casebox-search-input {
  width: 250px;
  outline: none;
  flex-grow: 1;
}
.casebox-create-btn{
  color: var(--Neutral-White, #FFF);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 12px;
  background: var(--Gradient-Dark, linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%));
  padding:12px;
  border: none;
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
  display: block;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 108px;
  margin-right: 10px;
}
.casebox-upload-btn {
  color: var(--Neutral-White, #FFF);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 12px;
  background: var(--Gradient-Dark, linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%));
  padding: 11px 20px;
  border: none;
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
  display: block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.casebox-create-btn:hover {
  transform: translateY(-2px);
  transform: scale(1.02);
  box-shadow: 0px 4px 20px 0px rgba(34, 121, 245, 0.25);
  background: linear-gradient(0deg, #1e6ad8 8.33%, #00bfe8 91.67%);
}

.casebox-delete-btn {
  color: var(--Neutral-20, #C4CBD4);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: space-evenly;
  width: 90px;
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 12px;
  border: 1px solid var(--Neutral-10, #E2E5E9);
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
}

.casebox-date-filter,
.casebox-date-filter-to {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 210px;
  justify-content: space-around;
  margin-right: 5px;
}

.casebox-date-filter-to {
  margin-left: -10px;
}

.casebox-checkbox .MuiSvgIcon-root {
  font-size: 1rem;
}

.casebox-table-row {
  background: var(--Neutral-White, #FFF);
  cursor: pointer;
  transition: background-color 0.2s ease-in, transform 0.2s ease-out;
}

.casebox-table-row:hover {
  background-color: #f0f0f0;
  transform: scale(1.01);
}

.casebox-table-head {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 0px 0px 5px 0px !important;
  border-bottom: none !important;
}

.casebox-table-details {
  color: var(--Neutral-50, #6C7C93);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.casebox-table-cell {
  padding: 0px !important;
  font-family: Roboto;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  height: 90px;
  border-bottom: none !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.casebox-table-container {
  padding: 20px;
  height: 500px;
}

.casebox-thumbnail {
  margin: 0;
  width: 90px;
  height: 60px;
  border-radius: 8px;
}

.casebox-icon-view {
  color: rgba(0, 210, 254, 1);
  width: 15px !important;
  height: 15px !important;
}

.casebox-icon-edit {
  color: rgba(34, 121, 245, 1);
  width: 15px !important;
  height: 15px !important;
}

.casebox-icon-delete {
  color: rgba(251, 59, 66, 1);
  width: 15px !important;
  height: 15px !important;
}

.casebox-icon-button {
  padding: 4px !important;
}

.casebox-row-spacer {
  height: 4px;
}

.casebox-pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

.icon-info {
  color: #00D2FE;
  width: 18px !important;

}

.icon-edit {
  color: #2279F5;
  width: 18px !important;

}

.icon-download {
  color: #2E7D32;
  width: 18px !important;

}

.icon-copy {
  color: #F58422;
  width: 18px !important;

}

.icon-view{
  color: rgba(0, 210, 254, 1);
  width: 18px !important;
}

.icon-share {
  color: #47D273;
  width: 18px !important;

}

.icon-delete {
  color: #FB3B42;
  width: 18px !important;

}

.slides-grid-container {
  margin: 1rem 0;
  padding: 1rem;
  background: var(--Gradient-Light, linear-gradient(0deg, #cee1fd 8.33%, #ccf6ff 91.67%));
  border-radius: 8px;
  max-height: 218px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.slide-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
}

.slide-nav-button.prev {
  left: 10px;
}

.slide-nav-button.next {
  right: 10px;
}

.slide-nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.slides-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  gap: 1rem;
  margin: 0 50px;
  overflow-x: auto;
  width: 100%;
}

/* Keep your existing slide-item styles */
.slide-item {
  border: 2px solid #ddd;
  border-radius: 8px;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 95px;
}

.slide-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.slide-item.selected {
  border-color: #2279f5;
  background-color: rgba(34, 121, 245, 0.1);
}

.slide-thumbnail{
  width: 52px;
  height: 45px;
}
.casebox-case{
  width: 100% !important;
  height: 200px !important;
}
.card-content{
  background: var(--Gradient-Light, linear-gradient(
    0deg
    , #CEE1FD 8.33%, #CCF6FF 91.67%));
}
/* Keeping existing media queries */
@media only screen and (max-width: 1635px) {
  .casebox-filter-box {
    width: 100%;
  }
}

@media only screen and (max-width: 1565px) {
  .casebox-upload-btn {
    font-size: 13px;
    padding: 10px;
    width: auto;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 1540px) {
  .casebox-container {
    height: 724px;
  }

  .casebox-filter-box {
    width: 85%;
  }

  .casebox-header-title {
    width: 230px;
  }

  .casebox-date-input,
  .casebox-search-input {
    padding: 0px 7px;
    border-radius: 12px;
    border: 1px solid var(--Primary-10, #CEE1FD);
    background: var(--Neutral-White, #FFF);
    height: 40px;
  }

  .casebox-date-input {
    width: 120px;
  }

  .casebox-search-input {
    width: 250px;
  }
}

@media only screen and (max-width: 1450px) {
  .casebox-header-title {
    width: 170px;
  }
}

@media only screen and (max-width: 1381px) {
  .casebox-upload-btn {
    display: none;
  }

  .casebox-upload-btn-desktop {
    display: block;
  }

  .casebox-header-title {
    font-size: 22px;
    width: 150px;
  }
}

@media only screen and (max-width: 1024px) {
  .casebox-date-filter,
  .casebox-date-filter-to {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 1000px) {
  .casebox-date-filter-to {
    display: none;
  }

  .casebox-date-filter-to-desktop {
    display: block;
    margin-right: -40px;
    margin-left: 10px;
  }

  .casebox-date-filter {
    margin-right: -50px;
  }

  .casebox-filter-box {
    margin-left: 80px;
  }
}

/* New responsive breakpoints for mobile */
@media only screen and (max-width: 768px) {
  .casebox-header {
    flex-direction: column;
    gap: 20px;
  }

  .casebox-filter-box {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
    gap: 15px;
  }

  .casebox-header-title {
    width: 100%;
    text-align: center;
  }

  .casebox-search {
    width: 100%;
  }

  .casebox-search-input {
    width: 100%;
  }

  .casebox-date-filter,
  .casebox-date-filter-to-desktop {
    width: 100%;
    margin: 0;
    justify-content: space-between;
  }

  .casebox-date-input {
    width: 70%;
  }

  .casebox-delete-btn {
    width: 100%;
    justify-content: center;
  }
}

@media only screen and (max-width: 480px) {
  .casebox-container {
    height: auto;
    min-height: 720px;
  }

  .casebox-header {
    padding: 15px;
  }

  .casebox-table-container {
    padding: 15px;
    height: auto;
    overflow-x: auto;
  }

  .casebox-table-cell {
    padding: 10px 5px !important;
  }
}