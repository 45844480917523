.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    background: var(--Gradient-Dark, linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%));
}

.navbar-div {
    width: 268px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 50px;
    gap: 7px;
}

.nav-image {
    width: 100%;
}

.nav-logo {
    width: 70%;
}

.nav-total {
    color: var(--Primary-10, #CEE1FD);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.nav {
    color: var(--Neutral-White, #FFF);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.nav-item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 50px;
    justify-content: space-between;

}

.avatar-name {
    color: var(--Neutral-White, #FFF);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.avatar-sub {
    color: var(--Primary-10, #CEE1FD);
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.avatar {
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.avtimage {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: opacity 0.3s ease;
    margin-left: 25px;
}

.avatar-info {
    position: absolute;
    top: 100%;
    left: 0%;
    transform: translateX(-50%);
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    transition: opacity 0.3s ease;
    padding: 10px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
    width: 170px;
    height: 70px;
    color: white;
}

.avtimage:hover+.avatar-info {
    opacity: 1;
}

.avatar-name,
.avatar-email {
    margin: 5px 0;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: white;
}