.microscope-viewer-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.viewer-window {
  position: relative;
  width: 100%;
  height: calc(100% - 60px);
  background-color: #000;
}

.toolbar {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 10px;
  background: var(--Gradient-Dark, linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%));  border-radius: 4px;
  border-top: 1px solid #dee2e6;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 7px;
}

.tool-group {
  display: flex;
  gap: 5px;
}

.tool-button {
  width: 29px;
  height: 29px;
  /* border: 1px solid #dee2e6; */
  /* background-color: white; */
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
  transition: all 0.2s ease;
}

.tool-button:hover {
  background-color: #5ba6f2;
  border-color: #ced4da;
}

.tool-button.active {
  background-color: #e9ecef;
  border-color: #ced4da;
}

.style-controls {
  display: flex;
  gap: 20px;
  align-items: center;
}

.color-picker,
.line-width {
  display: flex;
  align-items: center;
  gap: 8px;
}

.color-input {
  width: 40px;
  height: 30px;
  padding: 0;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}
.drawing-instructions-container {
  position: fixed;
  bottom: 35rem;
  left: 29%;
  transform: translateX(-50%);
  z-index: 1000;
  width: auto;
  max-width: 500px;
  animation: slideUp 0.3s ease-out;
}

.drawing-instructions {
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin: 0 1rem;
}

.drawing-instructions-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.5rem;
}

.drawing-instructions-content {
  font-size: 0.875rem;
  color: #4a5568;
  line-height: 1.4;
}
.width-input {
  width: 100px;
}

.width-value {
  min-width: 40px;
  text-align: right;
}

.control-group {
  display: flex;
  gap: 10px;
}

.control-button {
  padding: 8px 16px;
  border: 1px solid #dee2e6;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.control-button:hover {
  background-color: #f8f9fa;
  border-color: #ced4da;
}

.control-button.active {
  background-color: #e9ecef;
  border-color: #ced4da;
}

.control-button.clear {
  color: #dc3545;
  border-color: #dc3545;
}

.control-button.clear:hover {
  background-color: #dc3545;
  color: white;
}

.annotation-list {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 300px;
  max-height: calc(100% - 100px);
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.annotation-list-header {
  padding: 15px;
  border-bottom: 1px solid #dee2e6;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.annotation-list-item {
  padding: 12px 15px;
  border-bottom: 1px solid #dee2e6;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.annotation-list-item:hover {
  background-color: #f8f9fa;
}

.annotation-list-item.selected {
  background-color: #e9ecef;
}

.annotation-list-item .comment {
  margin-bottom: 8px;
  font-size: 14px;
}

.annotation-list-item .metadata {
  font-size: 12px;
  color: #6c757d;
  display: flex;
  justify-content: space-between;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0d6efd;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f8d7da;
  color: #842029;
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px solid #f5c2c7;
  text-align: center;
}

.comment-editor {
  padding: 15px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.comment-editor textarea {
  width: 100%;
  min-height: 80px;
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
}

.comment-editor .style-controls {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 15px;
}

.comment-editor .button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.r6o-editor .r6o-editor-inner {
  border-radius: 7px;
  background: linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%);
}
.r6o-editor .r6o-editor-inner .r6o-widget {
  background: linear-gradient(360deg, #CEE1FD 8.33%, #CCF6FF 91.67%);

  border-bottom: none;
}
.r6o-editor .r6o-footer .r6o-btn.delete-annotation{


  top: 4px;
  left: 21px;
}

.comment-editor .save-button {
  background-color: #0d6efd;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.comment-editor .cancel-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .toolbar {
    flex-direction: column;
    height: auto;
    padding: 15px;
    gap: 15px;
  }

  .style-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .annotation-list {
    position: fixed;
    right: 10px;
    top: 10px;
    width: calc(100% - 20px);
    max-width: 300px;
  }
}
/* Add these to your MicroscopeViewer.css */
.a9s-annotation {
  pointer-events: all;
}

.a9s-annotation:hover {
  filter: brightness(1.2);
}

.a9s-annotation.selected {
  filter: brightness(1.3);
}

.a9s-annotation path {
  vector-effect: non-scaling-stroke;
}

.a9s-handle {
  fill: var(--stroke-color, #ffffff);
  stroke: var(--fill-color, #000000);
  stroke-width: 1px;
}

.a9s-handle:hover {
  fill: var(--hover-fill, #ffffff);
  stroke: var(--hover-stroke, #000000);
}