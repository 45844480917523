/* SlideArchive.css */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 720px;
  border-radius: 12px 0px 0px 12px;
  background: #99edff;
  flex-direction: column;
}

.tools {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  flex-direction: column;
  position: fixed;
  top: 253px;
  z-index: 10;
  right: 32px;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.workSpace-filterBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 77%;
  gap: 24px;
}

.workspace-header-title {
  font-family: Roboto;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 350px;
}

.workspace-tag {
  display: flex;
  height: 18px;
  border-radius: 8px;
  padding: 6px 12px;
  background: var(--Primary-10, #cee1fd);
  align-items: center;
  color: rgba(34, 121, 245, 1);
}

.tags {
  display: flex;
  gap: 9px;
}

.workspace-container {
  width: 96%;
  display: flex;
  flex-direction: row-reverse;
  background-color: white;
}

.transform-component-module_content__FBWxo {
  height: 583px !important;
  /* padding: 0px 24px; */
}

.workspace-btn {
  width: 32px;
  height: 24px;
  border-radius: 8px 8px 0px 0px;
  /* border-bottom: 1px solid var(--Neutral-10, #E2E5E9); */
  border: 1px solid var(--Neutral-10, #e2e5e9);
  background-color: white;
}

.workspace-img {
  font-size: 20px !important;
  color: #0000009c !important;
}

.workspace-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* padding: 20px; */
  width: 96%;
}
.annotation-div {
  border-radius: 12px 12px 0px 0px;
  /* border-bottom: 1px solid var(--Neutral-10, #e2e5e9); */
  background: var(--Secondary-10, #ccf6ff);
  width: 100%;
  height: 60px;
  display: flex;
}

.workspace-image {
  margin: 0;
  max-width: unset;
}

.annotation-div1 {
  display: flex;
  align-items: center;
  width: 65%;
  justify-content: space-between;
  padding-left: 5px;
}
.slide-archive {
  height: 560px;
  width: 1437px;
}
.erase-annotations-btn{
  height: 30px;
  border: none;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
}
