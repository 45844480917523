.profile-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 200;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: all;

}

.profile-modal {
  position: absolute;
  background: white;
  padding: 25px;
  border-radius: 10px;
  width: 260px;
  height: 220px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  border: 0;
}
.avtimage1{
  width: 90px;
  height: 90px;
}
.detail{
  align-items: center;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
}
.name,
.mail {
  margin: 5px 0;
}
.name{
  color: black;
  font-weight: bolder
}
.mail{
  color: grey;
}
.profile-buttons {
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;
}

.button1,
.button2 {
  height: 43px;
  width: 120px;
  border-radius: 12px;
  border: 1px solid rgb(140, 114, 114);
  background-color: white;
  font-weight: bold;
  
}
.button1 {
  color: #2279F5;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.button1:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(34, 121, 245, 0.2);
}

.button1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #2279F5;
  transition: width 0.3s ease;
}

.button1:hover::after {
  width: 100%;
}

.button2 {
  color: #FB3B42;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.button2:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(251, 59, 66, 0.2);
}

.button2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #FB3B42;
  transition: width 0.3s ease;
}

.button2:hover::after {
  width: 100%;
}