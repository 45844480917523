.login-main {
    height: 100vh;
    width: 100vw;
    display: flex;
    overflow: hidden;

}

.image {
    width: 100%;
    height: 100%;
    display: block;
}

.image-desktop {
    width: 100%;
    height: 100%;
    display: none;
}

.login-img {
    width: 100%;
    height: 100%;
}

.login-div {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-clip: content-box;
    background: url("../../Assets/login_design.png"), linear-gradient(180deg, #2279F5 8.33%, #00D2FE 91.67%);
    background-size: cover;
}

.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.welcome-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.welcome-head {
    color: var(--Neutral-White, #FFF);
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 20px;
}

.welcome-head1 {
    margin-bottom: 4px;
}

.contain12 {
    display: flex;
    flex-direction: column;
    gap: 35px;
    width: 536px;
    height: 707px;
    border-radius: 12px;
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);

}

.contain1 {
    border-radius: 12px;
}

.txt8-login {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    margin: 1px 0px 20px 0px;
}

.label {
    color: var(--Neutral-50, #6C7C93);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.active-organization-tab {
    background: var(--Secondary-10, #CCF6FF) !important;
}

.css-1x5jdmq {
    border-radius: 12px;
}

.checkbox {
    border-radius: 4px !important;
    border: 1px solid var(--Neutral-20, #C4CBD4);
    background: var(--Neutral-White, #FFF);
}

.inpt-login {
    width: 100%;
    height: 42px;
    gap: 10px;
    border: 1px solid #c4cbd4;
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 12px;
    align-items: center;
}
.inpt-login1 {
    width: 100%;
    height: 42px;
    gap: 10px;
    border: 1px solid #c4cbd4;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 12px;
    align-items: center;
    border-top-right-radius:12px ;
    border-bottom-right-radius:12px ;
}


.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 12px !important;
    height: 42px !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 12px !important;
    height: 42px !important;
}

.css-1v4ccyo {
    border-radius: 12px !important;
    height: 42px !important;
}

.css-1bn53lx {
    border-radius: 12px !important;
    height: 42px !important
}

.forgot-password-div {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.forgot-password {
    color: rgba(34, 121, 245, 1);
    text-decoration: underline;
    cursor: pointer;
}

.btn-forgot {
    margin-top: 40px;
    width: 100%;
    height: 48px;
    background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
    border: none;
    border-radius: 12px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
}

.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;

}

.btn1-create-account {
    width: 100%;
    height: 48px;
    background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
    border: none;
    border-radius: 12px;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn1-create-account:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 210, 254, 0.3);
    background: linear-gradient(360deg, #1e6ad8 8.33%, #00b8e0 91.67%);
}

.or-divider {
    margin: 10px;
    font-size: 16px;
    color: #888;
    text-align: center;
}

.btn1-login1{
    background-color: white;
    border: 2px solid #2279f5;
    color: #2279f5;
    width: 100%;
    height: 48px;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;

}

.btn1-login1:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(228, 233, 235, 0.3);
}



.in-box {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 2px;
    width: auto;
    max-width: none
}

.error-message {
    position: absolute;
    color: red;
    font-size: 13px;
    margin-top: 30px;
    margin-bottom: -50px;
    background: transparent;
    border: 0;
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

@media only screen and (min-width: 1400px) {
    .contain12 {
        width: 60%;
        height: auto;

    }

    .txt8-login {
        font-size: 22px;
    }

    .forgot-password-div {
        flex-direction: row;
        font-size: 86%;
        width: 100%;
        padding: 0
    }

    .btn1-create-account,
    .btn1-login1 {
        height: 44px;
        font-size: 16px;
        margin-top: -15px;
    }
}


@media only screen and (max-width: 1400px) {
    .image {
        display: none;
    }

    .in-box-login {
        margin-top: -12px;
    }

    .image-desktop {
        display: block;
    }

    .contain12 {
        width: 70%;
        height: auto;
    }

    .txt8-login {
        font-size: 22px;
    }

    .forgot-password-div {
        flex-direction: row;
        font-size: 86%;
        width: 100%;
        padding: 0
    }

    .btn1-create-account,
    .btn1-login1 {
        height: 44px;
        font-size: 14px;
        margin-top: -8px;
    }
}

@media only screen and (max-width: 1074px) {
    .image {
        display: none;
    }

    .image-desktop {
        display: block;
    }

    .contain12 {
        width: 70%;
        height: auto;
    }

    .in-box-login {
        margin-top: -12px;
    }

    .txt8-login {
        font-size: 22px;
        margin-top: -8px;
    }

    .welcome-head {
        font-size: 24px;
    }

    .forgot-password-div {
        flex-direction: row;
        font-size: 86%;
        width: 100%;
        padding: 0
    }

    .btn1-create-account,
    .btn1-login1 {
        height: 44px;
        font-size: 14px;
        margin-top: -8px;
    }

}

@media only screen and (max-width: 1024px) {
    .image {
        display: none;
    }

    .image-desktop {
        display: block;
    }

    .contain12 {
        width: 70%;
        height: auto;
    }

    .in-box-login {
        margin-top: -12px;
    }

    .txt8-login {
        font-size: 18px;
        margin-top: -8px;
    }

    .welcome-head {
        font-size: 24px;
    }

    .forgot-password-div {
        flex-direction: row;
        font-size: 86%;
        width: 100%;
        padding: 0
    }

    .btn1-create-account,
    .btn1-login1 {
        height: 40px;
        font-size: 12px;
        margin-top: -8px;
    }


}

@media only screen and (max-width: 805px) {
    .contain12 {
        width: 85%;
        height: auto;
    }

    .btn1-create-account,
    .btn1-login1 {
        height: 40px;
        font-size: 12px;
        margin-top: -8px;
    }

    .forgot-password-div {
        flex-direction: row;
        font-size: 75%;
        width: 110%;
        padding-left: -10px;
    }
}


@media only screen and (max-width: 768px) {
    .contain12 {
        width: 80%;
        height: auto;
    }

    .welcome-container {
        margin-bottom: 20px;
    }

    .forgot-password-div {
        flex-direction: row;
        font-size: 86%;
        width: 100%;
        padding: 0
    }

    .btn1-create-account,
    .btn1-login1 {
        height: 44px;
        font-size: 14px;
        margin-top: -8px;
    }

    .txt8-login {
        font-size: 16px;
    }

}