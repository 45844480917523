.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  box-shadow: 24px;
  padding: 24px;
  background-color: #fff;
  outline: none;
  height: 660px;
  border-radius: 12px;
  background: var(--Gradient-Light, linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%));
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
}
.modal-box-upload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  box-shadow: 24px;
  padding: 24px;
  background-color: #fff;
  outline: none;
  border-radius: 12px;
  background: var(--Gradient-Light, linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%));
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 12px !important;
  background: var(--Neutral-White, #FFF) !important;

}

.css-1bp1ao6 {
  border-radius: 12px !important;
  background: var(--Neutral-White, #FFF) !important;
}

.css-bdozmo-MuiFormControl-root-MuiTextField-root {
  border-radius: 12px !important;
  background: var(--Neutral-White, #FFF) !important;
}

.submit-div {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.label{
  margin-bottom: 5px;
  margin-left: 6px;
  
}
.save-div {
  color: #FFF;
  border-radius: 12px;
  background: var(--Gradient-Dark, linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%));
  display: flex;
  padding: 16.5px 40px;
  justify-content: center;
  align-items: center;
  width: 180px;
  box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
  cursor: pointer;
  position: relative;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid transparent;
  border-top: 3px solid #FFF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  left: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.cancel-div {
  display: flex;
  padding: 16.5px 40px;
  justify-content: center;
  align-items: center;
  width: 170px;
  border-radius: 12px;
  border: 1px solid var(--Neutral-20, #C4CBD4);
  background: var(--Neutral-White, #FFF);
  cursor: pointer;
}