.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    pointer-events: all;
    backdrop-filter: blur(2px);

  }
  
  .password-modal {
    background: linear-gradient(0deg, #cee1fd 8.33%, #ccf6ff 91.67%);
    padding: 25px;
    border-radius: 10px;
    width: 500px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    pointer-events: auto;
    position: relative;
    border: none;
    height: auto;
    /* border-image: linear-gradient(0deg, #2279F5 ̧8.33%,#00D2FE 91.67%) 1; */
}
.password-main{
  background: linear-gradient(0deg, #2279F5 8.33%, #00D2FE 91.67%);
  padding: 3px;
  border-radius: 10px;
  border: none;
}

  .title{
    font-size: 20px;
    color: rgb(7, 7, 7);
    text-align: center;
    margin-top: -5px;
  }
  .para1{

    text-align: center;
    padding: 20px;
    margin-top: -15px;
  }
  .email-label {
    margin-top: -20px;
    display: block;
    margin-bottom: 16px;
    font-size: 14px;
    margin-left: 7px;
    font-weight: 500;
    color: #8a8181;
}
  .email-input {
    margin-top: -10px;
    width: 94%;
    height: 25px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 12px;
    align-items: center;
    resize: none;
    outline: none;
}
.cancel-button1,
.submit-button1 {
  padding: 10px 20px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  width: 44%;
  height: 45px;
  text-align: center;
  font-weight: bolder;
  margin-top: 50px;
  
}
.cancel-button1 {
  background: #ffffff;
}

.submit-button1 {
  background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
  color: white;
  margin-left: 50px;
}
.submit-button1:hover {
  transform: translateY(1px);
  box-shadow: 0 4px 12px rgba(0, 210, 254, 0.3);
  background: linear-gradient(360deg, #1e6ad8 8.33%, #00b8e0 91.67%);
}

.cancel-button1:hover {
  transform: translateY(1px);
  box-shadow: 0 4px 12px rgba(228, 233, 235, 0.3);
}
  