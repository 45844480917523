.error-modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    pointer-events: all;
    backdrop-filter: blur(2px);

  }
  .error-modal {
    background: linear-gradient(0deg, #cee1fd 8.33%, #ccf6ff 91.67%);
    padding: 25px;
    border-radius: 10px;
    width: 450px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    pointer-events: auto;
    position: relative;
  }
  .title{
    font-size: 24px;
    color: rgb(7, 7, 7);
    text-align: center;
    padding-bottom: 10px;

  }
  .title-button1 {
    font-size: larger;
    font-weight:bold;
    padding: 10px 20px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    width: 100%;
    height: 50px;
    text-align: center;
    background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
    color: white;
  }
  .title-button1:hover {
    transform: translateY(1px);
    box-shadow: 0 4px 12px rgba(0, 210, 254, 0.3);
    background: linear-gradient(360deg, #1e6ad8 8.33%, #00b8e0 91.67%);
  }