.opinion-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Overlay background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    pointer-events: all;
    backdrop-filter: blur(5px); /* Optional blur effect for background */
  }
  
  .opinion-modal {
    background: linear-gradient(0deg, #cee1fd 8.33%, #ccf6ff 91.67%);
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    pointer-events: auto;
    position: relative;
  }
  
  .title{
    font-size: 20px;
    font-weight: bold;
    padding-left: 9px;
  }
  
  
  .reason-title {
    font-size: 14px;
    color: rgb(93, 92, 87);
    margin-bottom: 15px;
  }
  
  .input-group {
    margin-bottom: 10px;
  }
  
  .reason-textarea {
    margin-top: -10px;
    width: 94%;
    height: 90px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    resize: none;
    outline: none;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .cancel-button,
  .submit-button {
    padding: 10px 20px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    width: 48%;
    text-align: center;
    font-weight: bolder;
  }
  
  .cancel-button {
    background: #ddd;
  }
  
  .submit-button {
    background: linear-gradient(360deg, #2279f5 8.33%, #00d2fe 91.67%);
    color: white;
  }
  
  .submit-button:hover {
    transform: translateY(1px);
    box-shadow: 0 4px 12px rgba(0, 210, 254, 0.3);
    background: linear-gradient(360deg, #1e6ad8 8.33%, #00b8e0 91.67%);
  }
  
  .cancel-button:hover {
    transform: translateY(1px);
    box-shadow: 0 4px 12px rgba(228, 233, 235, 0.3);
  }
  
  