/* Base container styles */
.microscope-slide-viewer {
  width: 100%;
  margin: 0 auto;
  background-color: #f0f8ff;
  border-radius: 10px;
  overflow: hidden;
  height: 720px;

}

/* Toolbar styles */
.toolbar {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background-color: #99EDFF;
  border-bottom: 1px solid #e0e0e0;
  flex-wrap: wrap;
  flex: 0 0 auto;
  position: sticky;
  top: 0;

}

.toolbar-group {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
  min-height: 40px;
}

.toolbar-separator {
  width: 1px;
  height: 24px;
  background-color: #e0e0e0;
  margin: 0 8px;
}

/* Tool button styles */
.tool-button-wrapper {
  position: relative;
}

.tool-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 20px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  border: 1px solid transparent;
}

.tool-button:hover {
  background-color: #f5f5f5;
}

.tool-button.selected {
  background-color: #007bff;
  color: white;
}

.tool-button.selected svg {
  fill: white;
}

/* Style controls */
.style-control {
  display: flex;
  align-items: center;
  gap: 8px;
}

.style-control label {
  font-size: 14px;
  color: #333;
  white-space: nowrap;
}

.style-control select,
.style-control input[type="text"],
.style-control input[type="number"] {
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  max-width: 120px;
}

.style-control input[type="color"] {
  width: 32px;
  height: 32px;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

/* Tooltip styles */
.tooltip {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  z-index: 1000;
}

.tool-button-wrapper:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

/* Viewer container styles */
.viewer-container {
  width: 100%;
  height: 634px;
  position: relative;
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.openseadragon-container {
  border: 2px solid #ccf6ff !important;
  flex: 1;
}

.openseadragon-container:hover {
  border: 2px solid #ccf6ff !important;
}

.openseadragon-viewer {
  width: 100%;
  height: 100%;
}

.openseadragon-canvas {
  background-color: white !important;
}

/* OpenSeadragon specific styles */
.openseadragon-canvas > div {
  pointer-events: auto !important;
}

[data-id] {
  transform-origin: top left;
  will-change: transform;
}

.is-selecting {
  cursor: crosshair !important;
}

/* Controls section */
.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  position: sticky;
  bottom: 20px;
  width: 100%;
  pointer-events: none;
}

.zoom-controls {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  gap: 4px;
  pointer-events: auto;
  background: rgba(255, 255, 255, 0.9);
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.button-zoom {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid rgb(231 231 231);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.button-zoom.selected {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
}

.button-zoom:hover {
  background-color: #e0e0e0;
}

/* Overlay and Messages */
.capturing-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 15px 25px;
  border-radius: 4px;
  z-index: 1000;
  font-size: 14px;
}

.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(220, 53, 69, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 80%;
}

/* Delete annotation button */
.delete-annotation {
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  pointer-events: auto;
}

.delete-annotation button {
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}


.delete-annotation button:hover {
  background-color: #c82333;
}

/* Fullscreen styles */
.microscope-slide-viewer.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background: white;
  border-radius: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.microscope-slide-viewer.fullscreen .viewer-container {
  height: auto;
  flex: 1;
  overflow: hidden;
}

.microscope-slide-viewer.fullscreen .openseadragon-viewer {
  height: 100%;
}

.microscope-slide-viewer.fullscreen .controls {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

/* Responsive styles */
@media (max-width: 1200px) {
  .toolbar {
    gap: 4px;
  }

  .toolbar-group {
    gap: 4px;
  }

  .style-control {
    gap: 4px;
  }

  .style-control input[type="text"] {
    max-width: 100px;
  }

  .toolbar-separator {
    margin: 0 4px;
  }
  
  .zoom-controls {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .toolbar {
    flex-wrap: wrap;
    gap: 8px;
  }

  .toolbar-group {
    flex: 1 1 auto;
    justify-content: flex-start;
    min-width: max-content;
  }

  .toolbar-separator {
    display: none;
  }

  .toolbar-group:nth-child(5) {
    flex-basis: 100%;
    flex-wrap: wrap;
    gap: 12px;
  }

  .style-control {
    flex: 1 1 auto;
    min-width: 180px;
    max-width: 250px;
  }

  .viewer-container {
    height: 500px;
  }
}

@media (max-width: 768px) {
  .toolbar {
    padding: 8px;
  }

  .toolbar-group {
    gap: 4px;
    flex-wrap: wrap;
    flex-basis: 100%;
    justify-content: flex-start;
  }

  .style-control {
    flex: 1 1 auto;
    min-width: 160px;
  }

  .style-control input[type="text"],
  .style-control select {
    flex: 1;
    min-width: 100px;
  }

  .tooltip {
    display: none;
  }

  .viewer-container {
    height: 400px;
  }

  .button-zoom {
    width: 36px;
    height: 36px;
    padding: 8px;
    font-size: 12px;
  }
}

/* Landscape mode */
@media (max-height: 500px) and (orientation: landscape) {
  .microscope-slide-viewer.fullscreen .toolbar {
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 8px;
    gap: 12px;
  }

  .toolbar-group {
    flex: 0 0 auto;
  }

  .toolbar-separator {
    display: block;
  }

  .toolbar::-webkit-scrollbar {
    height: 6px;
  }

  .toolbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .toolbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  .toolbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .microscope-slide-viewer.fullscreen .viewer-container {
    height: calc(100vh - 100px);
  }

  .microscope-slide-viewer.fullscreen .controls {
    bottom: 5px;
  }

  .microscope-slide-viewer.fullscreen .delete-annotation {
    bottom: 50px;
  }
}

/* Touch device optimizations */
@media (hover: none) {
  .tool-button {
    padding: 8px;
  }

  .style-control select,
  .style-control input[type="text"],
  .style-control input[type="number"] {
    padding: 8px;
  }

  .tooltip {
    display: none;
  }
}

/* High DPI screens */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .tool-button {
    border-width: 0.5px;
  }
  
  .toolbar-separator {
    width: 0.5px;
  }
}

/* Annotation selection styles */
.annotation-selected {
  z-index: 1;
}

/* Print styles */
@media print {
  .toolbar,
  .controls,
  .delete-annotation {
    display: none !important;
  }

  .microscope-slide-viewer {
    height: auto;
    overflow: visible;
  }

  .viewer-container {
    height: 100vh;
  }
}