.demo-container {
    background: linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%);
    box-shadow: 0px 2px 40px 0px rgba(34, 121, 245, 0.15);
    width: 100%;
    height: 720px;
    position: relative;
}

.demo-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
}

.demo-text {
    text-align: center;
    max-width: 500px;
}

.demo-title {
    font-size: 24px;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 8px;
}

.demo-description {
    font-size: 16px;
    color: #4b5563;
    line-height: 1.5;
}

@media only screen and (max-width: 1540px) {
    .demo-container {
        height: 700px;
    }
}

@media only screen and (max-width: 640px) {
    .demo-title {
        font-size: 20px;
    }
    
    .demo-description {
        font-size: 14px;
    }
}