.modal-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.detail-content1 {
  background: linear-gradient(0deg, #cee1fd 8.33%, #ccf6ff 91.67%);
  padding: 20px;
  border-radius: 8px;
  width: 450px;
 height: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  position: relative;
}

.modal-title1 {
  font-size: 1.5rem;
  color: black;
  text-align: center;
  margin-top: -5px;
  font-weight: bolder;
}

.modal-content1 {
  display: flex;
  flex-direction: column;
}

.modal-row1 {
  display: flex; 
  justify-content: space-between; 
  align-items: center; 
  font-size: 1rem;
  color: #936f6f;
  margin: -12px 0; 
  padding: 8px;
}

.label1 {
  font-weight:normal;
  font-size: small;
  color: #886f6f;
  background-color: white;
  width: 50%;
  height: 20px;
  padding: 10px;
  border-top-left-radius:12px;
  border-bottom-left-radius: 12px;

}

.value1 {
  width: 50%;
  height: 20px;
  padding: 10px;
  margin-top: 13px;
  text-align: right; 
  font-weight:400;
  font-size: small;
  color: rgb(70, 70, 229);
  background-color: white;
  border-top-right-radius:12px;
  border-bottom-right-radius: 12px;
  overflow: hidden; 
  word-wrap: break-word; 
  word-break: break-word; 
  white-space: normal;
}

